/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Edit,
  Identifier,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const UserEditEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    /* Passing " " as title disables the custom title */

    <Edit title=" " id={id}>
      <SimpleForm>
        <TextInput source="ean" autoFocus validate={[required()]} />
        <TextInput source="name_en" validate={[required()]} />
        <TextInput source="name_de" validate={[required()]} />
        <ReferenceInput source="category_id" reference="product_category">
          <SelectInput optionText="name_en" />
        </ReferenceInput>
        <NumberInput source="price" validate={[required()]} />
        <BooleanInput source="tax_inclusive" defaultChecked />
        <TextInput source="picture" />
      </SimpleForm>
    </Edit>
  );
};

UserEditEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default UserEditEmbedded;
