import { useRecordContext } from 'react-admin';
import ReactJson from 'react-json-view';

export const JsonViewField = (props: any) => {
  const record = useRecordContext(props);
  // extract json from string message
  try {
    const json = record.message.match(/({.*})/);
    if (json && json.length > 0) {
      // const out = JSON.parse(json);
      let out = json[0];
      if (out.includes(' - ')) {
        out = out.split(' - ')[1];
      }
      return record ? <ReactJson src={JSON.parse(out)} /> : null;
    } else {
      return <></>;
    }
  } catch (e) {
    return <>{record.message}</>;
  }
};

JsonViewField.defaultProps = { label: 'Json' };
