import LocalBarIcon from '@mui/icons-material/LocalBar';

import ProductCreate from './ProductCreate';
import ProductList from './ProductList';

export default {
  list: ProductList,
  create: ProductCreate,
  icon: LocalBarIcon,
  recordRepresentation: (record: any) => `${record.key}`,
};
