/* eslint react/jsx-key: off */
import { Edit, Identifier, ReferenceInput, SelectInput, SimpleForm, usePermissions } from 'react-admin';
import { Role_Enum } from 'src/utils/enum';

const RoomEditEmbedded = ({ id }: { id?: Identifier }) => {
  const { permissions }: { permissions: string } = usePermissions();
  const isMaster = permissions === Role_Enum.Master;

  return (
    <Edit title=" " id={id}>
      <SimpleForm>
        {isMaster && (
          <ReferenceInput
            label="Group"
            source="group_id"
            reference="group"
            perPage={320}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput placeholder="Select ..." label="Group" optionText="name" />
          </ReferenceInput>
        )}
        {isMaster && (
          <ReferenceInput
            label="Energy Saving"
            source="energy_saving_id"
            reference="object"
            perPage={320}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput label="Energy Saving" optionText="name" />
          </ReferenceInput>
        )}
        {isMaster && (
          <ReferenceInput
            label="Housekeeping"
            source="object_id"
            reference="object"
            perPage={320}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput label="Housekeeping" optionText="name" />
          </ReferenceInput>
        )}
        {isMaster && (
          <ReferenceInput
            label="Language Control"
            source="language_id"
            reference="object"
            perPage={320}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput label="Language" optionText="name" />
          </ReferenceInput>
        )}
        {isMaster && (
          <SelectInput
            source="room_state"
            choices={[
              { id: 1, name: 'Reserved' },
              { id: 2, name: 'Occupied' },
              { id: 0, name: 'Not in Use' },
            ]}
          />
        )}
        <ReferenceInput
          label="Language"
          source="language"
          reference="language"
          perPage={320}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput label="Language" optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default RoomEditEmbedded;
