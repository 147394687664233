import { Box, Card, CardContent, Divider, Grid, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { Identifier, Show, ShowProps, TextField, usePermissions } from 'react-admin';
import { Role_Enum } from 'src/utils/enum';
import { BlcIdField } from './BlcIdField';
import { CodeView } from './CodeView';
import { DeleteGuestButton } from './DeleteGuestButton';
import { FoundInLoxoneView } from './FoundInLoxoneView';
import { GroupButton } from './GroupButton';
import { LearnButton } from './LearnButton';
import { LogView } from './LogView';
import { NFCField } from './NFCField';
import { SendMail } from './SendMail';

// Import Material-UI icons
import ComputerIcon from '@mui/icons-material/Computer';
import HotelIcon from '@mui/icons-material/Hotel';
import KeyIcon from '@mui/icons-material/Key';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonIcon from '@mui/icons-material/Person';

interface CardFieldProps {
  label: string;
  icon: React.ReactElement;
  children: React.ReactNode;
}

const CardField: React.FC<CardFieldProps> = ({ label, icon, children }) => (
  <Card variant="outlined" sx={{ mb: 2 }}>
    <CardContent sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
        {React.cloneElement(icon, { sx: { mr: 1 } })}
        <Box component="span" sx={{ ml: 1 }}>
          {label}
        </Box>
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {children}
    </CardContent>
  </Card>
);

interface FieldWrapperProps {
  label: string;
  children: React.ReactNode;
}

const FieldWrapper: React.FC<FieldWrapperProps> = ({ label, children }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
      {label}
    </Typography>
    {children}
  </Box>
);

interface KeyViewEmbeddedProps extends Omit<ShowProps, 'children'> {
  id?: Identifier;
}

const KeyViewEmbedded: React.FC<KeyViewEmbeddedProps> = ({ id, ...props }) => {
  const { permissions } = usePermissions<Role_Enum>();

  const showSx: SxProps<Theme> = {
    '& .RaShow-main': {
      margin: 0,
      padding: 0,
    },
  };

  return (
    <Show title=" " id={id} queryOptions={{ refetchInterval: 5000 }} sx={showSx} {...props}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CardField label="Reservation Details" icon={<HotelIcon />}>
              <FieldWrapper label="Key ID">
                <TextField source="id" />
              </FieldWrapper>
              <FieldWrapper label="Confirmation Number">
                <TextField source="confirmation_number" />
              </FieldWrapper>
              {permissions === Role_Enum.Master && (
                <FieldWrapper label="Reservation ID">
                  <TextField source="reservation_id" />
                </FieldWrapper>
              )}
              <FieldWrapper label="Room Number">
                <TextField source="number" />
              </FieldWrapper>
            </CardField>

            <CardField label="Guest Information" icon={<PersonIcon />}>
              <FieldWrapper label="Guest ID">
                <TextField source="guest_id" />
              </FieldWrapper>
              <FieldWrapper label="Email">
                <TextField source="email" emptyText="No Email Address" />
              </FieldWrapper>
              <FieldWrapper label="Phone">
                <TextField source="phone" emptyText="No Phone Number" />
              </FieldWrapper>
            </CardField>
          </Grid>

          <Grid item xs={12} md={6}>
            <CardField label="System Information" icon={<ComputerIcon />}>
              <FieldWrapper label="BLC ID">
                <BlcIdField source="blc_id" />
              </FieldWrapper>
              {(permissions === Role_Enum.Master || permissions === Role_Enum.Superuser) && (
                <FieldWrapper label="Found in Loxone">
                  <FoundInLoxoneView />
                </FieldWrapper>
              )}
              <FieldWrapper label="NFC TAG">
                <NFCField source="nfc_ids" emptyText="No Tags" />
              </FieldWrapper>
            </CardField>

            <CardField label="Access" icon={<KeyIcon />}>
              <Box sx={{ '& > *': { mb: 1 } }}>
                <CodeView label="Code" className="codeView" />
                <SendMail />
                <LearnButton />
                <GroupButton />
                {permissions === Role_Enum.Master && <DeleteGuestButton />}
              </Box>
            </CardField>
          </Grid>

          {permissions === Role_Enum.Master && (
            <Grid item xs={12}>
              <CardField label="Logs" icon={<ListAltIcon />}>
                <LogView />
              </CardField>
            </Grid>
          )}
        </Grid>
      </Box>
    </Show>
  );
};

export default KeyViewEmbedded;
