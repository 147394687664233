/* eslint react/jsx-key: off */
import {
  Datagrid,
  DateField,
  FieldProps,
  Identifier,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { FullNameField } from '../users/FullNameField';
const Base64Image: React.FC<FieldProps> = (props) => {
  const { source } = props;
  const record = useRecordContext(props);

  if (!record || !source || !record[source]) {
    return null;
  }

  return <img src={record[source]} alt="Signature" />;
};
const OrderViewEmbedded = ({ id }: { id?: Identifier }) => (
  <Show title=" " id={id}>
    <SimpleShowLayout>
      <NumberField source="id" />
      <ReferenceField label="Room Number" source="room_id" reference="room">
        <TextField source="number" />
      </ReferenceField>
      <ReferenceField source="guest_id" reference="guest">
        <FullNameField />
      </ReferenceField>
      <NumberField label="Amount EUR" source="amount" />
      <Base64Image label="Signature" source="sign" />
      <DateField source="created_at" showTime locales="de-AT" />
      <ReferenceManyField label="Items" reference="item" target="order_id">
        <Datagrid>
          <ReferenceField label="Name" source="product_id" reference="product">
            <TextField source="name_de" />
          </ReferenceField>
          <NumberField source="sale_price" />
          <NumberField source="quantity" />
          <NumberField source="amount_total" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default OrderViewEmbedded;
