/* eslint react/jsx-key: off */
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectField,
  SelectInput,
  TextField,
  usePermissions,
} from 'react-admin';

import { Role_Enum } from 'src/utils/enum';
import RoomEditEmbedded from './RoomEditEmbedded';

const SyncList = () => {
  const { permissions } = usePermissions();
  const roomFilters = [
    <SearchInput source="number" alwaysOn />,
    <ReferenceInput source="status" reference="room_status" alwaysOn>
      <SelectInput optionText="name" source="id" />
    </ReferenceInput>,
    <ReferenceInput source="group_id" reference="group" alwaysOn>
      <SelectInput optionText="name" source="id" />
    </ReferenceInput>,
    <ReferenceInput source="service_status" reference="room_service_status" alwaysOn>
      <SelectInput optionText="name" source="id" />
    </ReferenceInput>,
  ];
  return (
    <List
      sort={{
        field: 'updated_at',
        order: 'DESC',
      }}
      filters={roomFilters}
    >
      <Datagrid rowClick="expand" expand={<RoomEditEmbedded />} optimized>
        <TextField source="number" />
        <ReferenceField source="group_id" reference="group">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="status" reference="room_status">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="language" reference="language">
          <TextField source="name" />
        </ReferenceField>

        <BooleanField source="occupied" emptyText="None" />
        <SelectField
          source="room_state"
          choices={[
            { id: 0, name: 'Not set' },
            { id: 1, name: 'Reserved' },
            { id: 2, name: 'Occupied' },
          ]}
        />
        <ReferenceField source="energy_saving_id" reference="object">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="object_id" reference="object">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="language_id" reference="object">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="max_occupancy" emptyText="None" />
        <ReferenceField source="service_status" reference="room_service_status">
          <TextField source="name" />
        </ReferenceField>
        {permissions === Role_Enum.Master && <ReferenceField source="created_by" reference="user" />}
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default SyncList;
