import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, IconButton, Input } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNotify, useRecordContext, useUpdate } from 'react-admin';

export const BlcIdField = (props: any) => {
  const record = useRecordContext(props);
  const [blcId, setBlcId] = useState<string>('');
  const [update, { isLoading }] = useUpdate();

  const notify = useNotify();

  useEffect(() => {
    if (record && record.blc_id) {
      setBlcId(record.blc_id);
    }
  }, [record]);

  if (!record) return <></>;

  const handleSave = async (blc_id: string) => {
    // trim the string
    update('key', { id: record.id, data: { blc_id: blc_id.trim() }, previousData: record })
      .then(() => {
        notify('common.save_success', { type: 'success' });
      })
      .catch(() => {
        notify('common.save_error', { type: 'error' });
      });
  };
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Input value={blcId} onChange={(e) => setBlcId(e.target.value)} placeholder="Blc ID" style={{ width: 260 }} />
        <IconButton color="success" disabled={isLoading} onClick={() => handleSave(blcId)}>
          <CheckCircleOutlineIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

BlcIdField.defaultProps = { label: 'Burgenlend Card ID' };
