/* eslint react/jsx-key: off */
import { ReactElement } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  usePermissions,
} from 'react-admin';
import { CreateKeyButton } from './CreateKeyButton';

import { Role_Enum } from 'src/utils/enum';
import ReservationViewEmbedded from './ReservationViewEmbedded';
// function that returns an array of filters in react admin
const getGuestFilters = (): ReactElement[] => {
  const filters = [
    <SearchInput source="confirmation_number" alwaysOn />,
    <ReferenceInput source="room_id" reference="room" alwaysOn perPage={100} sort={{ field: 'number', order: 'ASC' }}>
      <SelectInput optionText="number" />
    </ReferenceInput>,
    <DateField source="arrival_date" locales="de-AT" />,
    <DateField source="departure_date" locales="de-AT" />,
  ];
  return filters.filter((filter) => filter !== null) as ReactElement[];
};

const ReservationList = () => {
  const { permissions } = usePermissions();

  return (
    <List
      filters={getGuestFilters()}
      sort={{
        field: 'arrival_date',
        order: 'DESC',
      }}
    >
      <Datagrid expand={<ReservationViewEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <TextField source="confirmation_number" />
        <ReferenceField source="room_id" reference="room">
          <TextField source="number" />
        </ReferenceField>
        <DateField source="arrival_time" showTime locales="de-AT" />
        <DateField source="departure_time" showTime locales="de-AT" />
        <CreateKeyButton />
        {permissions === Role_Enum.Master && <ReferenceField source="created_by" reference="user" />}
        <DateField source="created_at" showTime />
      </Datagrid>
    </List>
  );
};

export default ReservationList;
