/* eslint react/jsx-key: off */
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { FullNameField } from '../users/FullNameField';
import LogShow from './LogShow';

const LogList = () => {
  const logFilters = [
    <SearchInput placeholder="Search title" source="title" alwaysOn />,
    <SearchInput placeholder="Search message" source="message" alwaysOn />,
    <ReferenceInput source="module" reference="log_module" alwaysOn>
      <SelectInput optionText="name" source="id" />
    </ReferenceInput>,
    <ReferenceInput source="level" reference="log_level" alwaysOn>
      <SelectInput optionText="name" source="id" />
    </ReferenceInput>,
  ];
  return (
    <List sort={{ field: 'created_at', order: 'DESC' }} filters={logFilters}>
      <Datagrid expand={<LogShow />} optimized rowClick="expand">
        <TextField source="id" />
        <TextField source="title" />
        <ReferenceField source="module" reference="log_module">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="level" reference="log_level">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="created_by" reference="user">
          <FullNameField />
        </ReferenceField>
        <DateField source="created_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default LogList;
