/* eslint react/jsx-key: off */
import * as React from 'react';
import {
  DateTimeInput,
  Edit,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  required,
  useRecordContext,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

interface Props {
  [key: string]: any;
}
/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const ScheduleEditToolbar: React.FC<Props> = (props) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const FromInput = ({ disabled }: { disabled: boolean }) => {
  const { setValue } = useFormContext();
  return (
    <DateTimeInput
      source="from"
      disabled={disabled}
      validate={[required()]}
      onChange={(e) => {
        const value = new Date(e.target.value);
        value.setHours(value.getHours() + 1);
        setValue('to', value);
      }}
    />
  );
};

const ScheduleEditForm = () => {
  const record = useRecordContext();

  return (
    <SimpleForm
      toolbar={<ScheduleEditToolbar />}
      validate={(data) => {
        const errors = {} as any;
        if (data.from > data.to) {
          errors.from = ['From must be before To'];
        }
        return errors;
      }}
    >
      <ReferenceInput source="key_id" reference="key_view">
        <SelectInput optionText="full_name" disabled />
      </ReferenceInput>
      <FromInput disabled={!record || record.state !== 'SCHEDULED'} />
      <DateTimeInput source="to" disabled={!record || record.state !== 'SCHEDULED'} validate={[required()]} />
      <ReferenceInput source="status" reference="schedule_status" disabled={!record || record.state !== 'SCHEDULED'}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  );
};
const ScheduleEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <ScheduleEditForm />
    </Edit>
  );
};

export default ScheduleEdit;
