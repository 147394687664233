import AddCardIcon from '@mui/icons-material/AddCard';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useGetList, useNotify, useRecordContext, useUpdate } from 'react-admin';
import { buttonStyles } from 'src/services/theme';

export const LearnButtonList = (props: any) => {
  const record: any = useRecordContext(props);
  const [update, { isLoading, error }] = useUpdate();
  const [isShowInstruction, setIsShowInstruction] = useState<boolean>(false);
  const [readerId, setReaderId] = useState<string | null>(null);
  const notify = useNotify();

  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const {
    data: readers,
    isLoading: isLoadingObject,
    error: errorGetObject,
  } = useGetList('object', {
    pagination: { page: 1, perPage: 2 },
    filter: { type: 'NfcCodeTouch', name: 'Bar' },
  });

  const [nfcLength, setNfcLength] = useState<number>(0);

  useEffect(() => {
    if (record.nfc_ids && isShowInstruction && record.nfc_ids.length > nfcLength) {
      // stop learn
      setIsShowInstruction(false);
      update('key', { id: record.id, data: { nfc_update_at: null, reader_id: readerId }, previousData: record });
      notify('NFC learn success', { type: 'success' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.nfc_ids, isShowInstruction]);

  const handleLearn = (reader_id: string, is_on_ncd: boolean) => {
    if (is_on_ncd) {
      update('key', { id: record.id, data: { nfc_update_at: moment().format(), reader_id }, previousData: record });
    } else {
      setReaderId(reader_id);
      update('key', { id: record.id, data: { nfc_update_at: moment().format(), reader_id }, previousData: record });
      setIsShowInstruction(true);
      setNfcLength(record.nfc_ids?.length ?? 0);
      setTimeLeft(10);
      setTimeout(() => {
        setIsShowInstruction(false);
        onStopLearn();
      }, 10000);
    }
  };

  const onStopLearn = () => {
    notify('NFC stop learn', { type: 'info' });
    update('key', { id: record.id, data: { nfc_update_at: null, reader_id: readerId }, previousData: record });
    setIsShowInstruction(false);
  };

  if (isLoadingObject || ['NOSHOW', 'CANCELED', 'CHECKEDOUT'].includes(record['status'])) {
    return <></>;
  }
  if (error || errorGetObject) {
    return <p>ERROR</p>;
  }
  return record ? (
    <>
      <Box>
        {readers
          ?.filter((r) => r.name == 'Bar')
          .flatMap((reader) => (
            <Button
              size="small"
              variant="contained"
              sx={buttonStyles.info}
              key={reader.id}
              disabled={isLoading}
              startIcon={<AddCardIcon />}
              onClick={() => {
                handleLearn(reader.id, reader.is_on_ncd);
              }}
            >
              {record.nfc_ids?.length ?? 0}
            </Button>
          ))}

        <Dialog open={isShowInstruction} onClose={onStopLearn}>
          <DialogTitle>Learn NFC Tag</DialogTitle>

          <DialogContent sx={{ minWidth: 300 }}>
            <img
              src="learnNFC.png"
              alt="learnNFC"
              width="100%"
              height="100%"
              style={{ objectFit: 'contain', marginBottom: '10px' }}
            />
            <DialogContentText>{record.full_name}</DialogContentText>
            <DialogContentText>{`Room ${record.number}`}</DialogContentText>
            <DialogContentText>{`Arrival ${moment(record.arrival_time).format(
              'DD.MM.YYYY, HH:mm',
            )}`}</DialogContentText>
            <DialogContentText style={{ marginBottom: 35 }}>
              {`Hold the NFC tag at the location shown on your nfc reader (${timeLeft}s)`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button sx={buttonStyles.warning} onClick={onStopLearn}>
              Stop
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  ) : null;
};

LearnButtonList.defaultProps = { label: 'Key Card' };
