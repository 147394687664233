import { Box, Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { TextField, useDataProvider, useRecordContext, useUpdate } from 'react-admin';
import { buttonStyles } from 'src/services/theme';

export const FoundInLoxoneView = (props: any) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [checking, setChecking] = useState<boolean>(true);

  const [update, { isLoading, error }] = useUpdate();
  const [found, setFound] = useState<boolean>(false);

  const fetchUserLoxone = useCallback(
    async (lx_user_uuid: string) => {
      try {
        setChecking(true);
        const response = await dataProvider.getOne('lx_user', { id: lx_user_uuid });

        if (response.data) {
          setFound(true);
        }
        setChecking(false);
      } catch (error) {
        setChecking(false);
        setFound(false);
      }
    },
    [dataProvider],
  );

  useEffect(() => {
    if (record.lx_user_uuid && record.lx_user_uuid !== '') {
      fetchUserLoxone(record.lx_user_uuid);
    } else {
      setChecking(false);
    }
  }, [record, fetchUserLoxone]);

  const handleClick = () => {
    update('key', { id: record.id, data: { lx_user_uuid: 'create' }, previousData: record });
  };

  if (checking) {
    return <p>Checking</p>;
  }

  if (error) {
    return <p>ERROR</p>;
  }
  const finalCheck = (record.status === 'RESERVED' || record.status === 'CHECKEDIN') && !found;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <TextField source="lx_user_uuid" emptyText="No User" />

      {finalCheck ? (
        <Button variant="contained" sx={buttonStyles.info} size="small" disabled={isLoading} onClick={handleClick}>
          Create User
        </Button>
      ) : null}
    </Box>
  );
};

FoundInLoxoneView.defaultProps = { label: 'Found in Loxone' };
