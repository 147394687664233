import CheckIcon from '@mui/icons-material/Check';
import PendingIcon from '@mui/icons-material/Pending';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

import { Typography } from '@mui/material';
import moment from 'moment';
import { useGetList, useRecordContext } from 'react-admin';
export const TimeSyncField = (props: any) => {
  const record: any = useRecordContext(props);

  const {
    data: lx_users,
    isLoading: loading,
    error: errorGetObject,
  } = useGetList('lx_user', {
    pagination: { page: 1, perPage: 4 },
    filter: { guest_id: record.guest_id, confirmation_number: record.confirmation_number },
  });

  if (loading) return <PendingIcon />;
  if (errorGetObject) return <Typography>ERROR</Typography>;
  if (!lx_users || lx_users.length === 0) return <Typography>N/A</Typography>;
  if (
    moment(lx_users[0].valid_from).diff(moment(record.arrival_time), 'hour') > 1 ||
    moment(lx_users[0].valid_to).diff(moment(record.departure_time), 'hour') > 1
  ) {
    return <SyncProblemIcon />;
  }

  return record ? <CheckIcon /> : null;
};

TimeSyncField.defaultProps = { label: 'Has sync' };
