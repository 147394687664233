import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { Confirm, useRecordContext, useUpdate } from 'react-admin';
export const NFCField = (props: any) => {
  const record = useRecordContext(props);
  const [selectedNFC, setSelectedNFC] = useState<string | undefined>();
  const [update] = useUpdate();

  if (!record || !record.nfc_ids || record.nfc_ids.length === 0) return <></>;
  const handleRemove = (nfcToRemove: string) => {
    update('key', {
      id: record.id,
      data: { nfc_ids: (record.nfc_ids as string[]).filter((id) => id !== nfcToRemove) },
      previousData: record,
    }).then(() => {
      setSelectedNFC(undefined);
    });
  };
  return (
    // <ul>

    <Box>
      {record.nfc_ids.map((item: string) => (
        <Box
          key={item}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: '20px',
          }}
        >
          <Box sx={{ width: '165px' }}> {item}</Box>
          <IconButton color="error">
            <DeleteIcon />
          </IconButton>
          <Box
            onClick={() => setSelectedNFC(item)}
            sx={{
              color: 'red',
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.8,
              },
            }}
          >
            Remove
          </Box>
        </Box>
      ))}
      <Confirm
        isOpen={selectedNFC !== undefined}
        // loading={isLoading}
        title="Remove nfc"
        content="Are you sure you want to remove this nfc?"
        onConfirm={() => handleRemove(selectedNFC ?? '-')}
        onClose={() => setSelectedNFC(undefined)}
      />
    </Box>
  );
};

NFCField.defaultProps = { label: 'NFCs' };
