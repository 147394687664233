/* eslint react/jsx-key: off */
import { ReactElement } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  usePermissions,
} from 'react-admin';

import { Role_Enum } from 'src/utils/enum';
import ScheduleViewEmbedded from './ScheduleViewEmbedded';
// function that returns an array of filters in react admin
const getGuestFilters = (): ReactElement[] => {
  const filters = [
    <ReferenceInput source="status" reference="schedule_status" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput source="state" reference="schedule_state" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>,
  ];
  return filters.filter((filter) => filter !== null) as ReactElement[];
};

const ScheduleList = () => {
  const { permissions } = usePermissions();

  return (
    <List
      filters={getGuestFilters()}
      sort={{
        field: 'from',
        order: 'DESC',
      }}
    >
      <Datagrid expand={<ScheduleViewEmbedded />} rowClick="edit" optimized>
        <TextField source="id" />
        <ReferenceField source="key_id" reference="key_view">
          <TextField source="full_name" />
        </ReferenceField>
        <ReferenceField source="status" reference="schedule_status">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="from" showTime locales="de-AT" />
        <DateField source="to" showTime locales="de-AT" />
        <ReferenceField source="state" reference="schedule_state">
          <TextField source="name" />
        </ReferenceField>
        {permissions === Role_Enum.Master && <ReferenceField source="created_by" reference="user" />}
        <DateField source="created_at" showTime />
      </Datagrid>
    </List>
  );
};

export default ScheduleList;
