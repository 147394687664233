/* eslint react/jsx-key: off */
import { Grid } from '@mui/material';
import { AutocompleteInput, Create, DateTimeInput, Form, ReferenceInput, SaveButton, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';

const FromInput = () => {
  const { setValue } = useFormContext();
  return (
    <DateTimeInput
      source="from"
      validate={[required()]}
      onChange={(e) => {
        const value = new Date(e.target.value);
        value.setHours(value.getHours() + 1);
        setValue('to', value);
      }}
    />
  );
};
const ScheduleCreate = () => {
  const filterGuestToQuery = (searchText: string) => ({ 'full_name@_ilike': `%${searchText}%` });

  return (
    <Create resource="schedule" redirect="list">
      <Form
        validate={(data) => {
          const errors = {} as any;
          if (data.from > data.to) {
            errors.from = ['From must be before To'];
          }
          return errors;
        }}
      >
        <Grid sx={{ padding: '20px' }} container spacing={1}>
          <Grid item xs={6}>
            <ReferenceInput filter={{ is_active: true }} source="key_id" reference="key_view">
              <AutocompleteInput
                optionText={(record) => `${record.full_name} - ${record.id}`}
                fullWidth
                filterToQuery={filterGuestToQuery}
                validate={[required()]}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <FromInput />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <DateTimeInput source="to" validate={[required()]} />
          </Grid>
          <Grid item xs={12}>
            <SaveButton />
          </Grid>
        </Grid>
      </Form>
    </Create>
  );
};

export default ScheduleCreate;
