import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';

export const LogView = (props: any) => {
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const [loxoneLogs, setLoxoneLogs] = useState<any[]>([]);
  const [otherLogs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchLog = useCallback(
    async (lx_user_uuid: string, confirmation_number: string) => {
      try {
        setIsLoading(true);
        const [response1, response2] = await Promise.all([
          dataProvider.getList('log', {
            filter: { 'message@_ilike': `%${lx_user_uuid}%` },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'created_at', order: 'DESC' },
          }),
          dataProvider.getList('log', {
            filter: { 'message@_ilike': `%${confirmation_number}%` },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'created_at', order: 'DESC' },
          }),
        ]);

        setIsLoading(false);
        const logs = [
          ...response1.data.filter((log: any) => !log.message.includes('getuserlist2')),
          ...response2.data.filter(
            (log: any) =>
              !log.message.includes('getuserlist2') && !log.message.includes('Received webhook from SNT Data'),
          ),
        ];

        setLoxoneLogs(logs.sort((a: any, b: any) => moment(b.created_at).diff(moment(a.created_at))));
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    },
    [dataProvider],
  );

  useEffect(() => {
    if (record.lx_user_uuid && record.lx_user_uuid !== '') {
      fetchLog(record.lx_user_uuid, record.confirmation_number);
    }
  }, [record, fetchLog]);

  if (isLoading) {
    return <p>Loading</p>;
  }
  if (loxoneLogs.length === 0 && otherLogs.length === 0) {
    return <p>No Logs</p>;
  }

  return (
    <Box width={1200} height={500} overflow="auto">
      <h3>Loxone Logs</h3>
      {loxoneLogs.map((log: any) => (
        <p key={log.id}>
          <Typography color="blue">{moment(log.created_at).format('DD/MM - HH:mm:ss')}</Typography>{' '}
          <span> {log.message}</span>
        </p>
      ))}
    </Box>
  );
};

LogView.defaultProps = { label: 'Log' };
