import { Box, Button } from '@mui/material';
import moment from 'moment';
import { ChipField, Link, ReferenceArrayField, SingleFieldList, useRecordContext, useUpdate } from 'react-admin';
import { buttonStyles } from 'src/services/theme';

export const GroupButton = (props: any) => {
  const record = useRecordContext(props);
  const [update, { isLoading }] = useUpdate();

  const handleSyncGroup = () => {
    update('key', { id: record.id, data: { sync_group_time: moment().format() }, previousData: record });
  };
  // Create link button

  // Edit button
  return record ? (
    <Box sx={{ padding: '10px 0' }}>
      <ReferenceArrayField
        label="Groups"
        source="group_ids"
        reference="group"
        sx={{ '& .RaReferenceArrayField-root': { marginTop: 16, marginBottom: 16 }, marginBottom: '10px' }}
      >
        <SingleFieldList>
          <Link to={`/key/${record.id}`}>
            <ChipField source="name" />
          </Link>
        </SingleFieldList>
      </ReferenceArrayField>
      <Button
        size="small"
        variant="contained"
        sx={{ ...buttonStyles.info, marginTop: '10px' }}
        component={Link}
        to={`/key/${record.id}`}
      >
        Edit
      </Button>

      <Button
        size="small"
        variant="contained"
        sx={{ ...buttonStyles.info, marginTop: '10px' }}
        onClick={() => handleSyncGroup()}
        disabled={isLoading}
      >
        Sync Group
      </Button>
    </Box>
  ) : null;
};

GroupButton.defaultProps = { label: 'Groups' };
