import { Button } from '@mui/material';
import { useState } from 'react';
import { Confirm, useDelete, useRecordContext } from 'react-admin';
import { buttonStyles } from 'src/services/theme';

export const DeleteGuestButton = (props: any) => {
  const record = useRecordContext(props);
  const [deleteOne, { isLoading, error }] = useDelete();
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);

  const handleRemove = () => {
    deleteOne('guest', { id: record.guest_id, previousData: record }).then(() => {
      setIsShowConfirm(false);
    });
  };

  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <>
      <Button
        size="small"
        variant="contained"
        sx={buttonStyles.warning}
        disabled={isLoading}
        onClick={() => setIsShowConfirm(true)}
      >
        Delete Guest
      </Button>
      <Confirm
        isOpen={isShowConfirm}
        loading={isLoading}
        title="Delete Guest"
        content="Are you sure you want to remove this Guest?"
        onConfirm={handleRemove}
        onClose={() => setIsShowConfirm(false)}
      />
    </>
  );
};

DeleteGuestButton.defaultProps = { label: ' ' };
