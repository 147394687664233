/* eslint react/jsx-key: off */
import { BooleanField, Datagrid, DateField, List, NumberField, ReferenceField, TextField } from 'react-admin';

import ProductEditEmbedded from './ProductEditEmbedded';

const ProductList = () => {
  return (
    <List
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
    >
      <Datagrid expand={<ProductEditEmbedded />} rowClick="expand" optimized>
        <NumberField source="id" />
        <TextField source="ean" />
        <TextField source="name_en" />
        <TextField source="name_de" />
        <NumberField source="price" />
        <ReferenceField source="category_id" reference="product_category">
          <TextField source="name_en" />
        </ReferenceField>
        <BooleanField source="tax_inclusive" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default ProductList;
