/* eslint react/jsx-key: off */
import * as React from 'react';
import { Edit, ReferenceInput, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar } from 'react-admin';

interface Props {
  [key: string]: any;
}
/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const RoomEditToolbar: React.FC<Props> = (props) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const RoomEditForm = () => {
  return (
    <SimpleForm toolbar={<RoomEditToolbar />}>
      <TextInput source="number" disabled />
      <TextInput source="status" disabled />
      <TextInput source="service_status" disabled />
      <ReferenceInput source="group_id" reference="group">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  );
};
const RoomEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <RoomEditForm />
    </Edit>
  );
};

export default RoomEdit;
