/* eslint react/jsx-key: off */
import { Identifier, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

const ScheduleViewEmbedded = ({ id }: { id?: Identifier }) => (
  <Show title=" " id={id}>
    <SimpleShowLayout>
      <ReferenceField label="Room Number" source="room_id" reference="room">
        <TextField source="number" />
      </ReferenceField>
      <ReferenceField label="Room Status" source="room_id" reference="room">
        <TextField source="status" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default ScheduleViewEmbedded;
