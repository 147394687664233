import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

// Custom validation function
const validatePositiveNumber = (value: number) => (value && value <= 0 ? 'Price must be greater than 0' : undefined);

const ProductCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm>
        <TextInput source="ean" autoFocus validate={[required()]} />
        <TextInput source="name_en" validate={[required()]} />
        <TextInput source="name_de" validate={[required()]} />
        <NumberInput source="price" validate={[required(), validatePositiveNumber]} />
        <ReferenceInput source="category_id" reference="product_category">
          <SelectInput optionText="name_en" />
        </ReferenceInput>
        <BooleanInput source="tax_inclusive" defaultChecked />
        <TextInput source="picture" />
      </SimpleForm>
    </Create>
  );
};

export default ProductCreate;
