import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { Confirm, TextField, useGetIdentity, useRecordContext, useUpdate } from 'react-admin';
import { buttonStyles } from 'src/services/theme';
export const CodeView = (props: any) => {
  const record = useRecordContext(props);
  const [update, { isLoading, error }] = useUpdate();
  const { identity } = useGetIdentity();
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);
  const env = process.env.NODE_ENV;

  const handleRemove = () => {
    update('key', { id: record.id, data: { code: '' }, previousData: record }).then(() => {
      setIsShowConfirm(false);
    });
  };

  const handleNewCodeClick = () => {
    // const code = Math.floor(100000 + Math.random() * 900000).toString();
    // update('key', { id: record.id, data: { code: code }, previousData: record });
    updateCode(Number(record.id));
  };

  const updateCode = async (keyId: number) => {
    const mutation = `
      mutation updateCode($key_id: Int!) {
        update_code(params: {
          key_id: $key_id
        }) {
          success
        }
      }
    `;

    const variables = {
      key_id: keyId,
    };

    try {
      const token = identity?.token;
      if (!token) {
        throw new Error('No token');
      }
      const baseApiUrl = process.env.REACT_APP_BASE_API_URL || '';
      const response = await axios({
        url: baseApiUrl,
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          query: mutation,
          variables: variables,
        },
      });

      const result = response.data;
      console.log('Mutation result:', result);
      return result.data.update_code.success;
    } catch (error) {
      console.error('Error executing mutation:', error);
      throw error;
    }
  };

  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {env !== 'production' && (
        <Typography variant="body2" sx={{ mr: 1 }}>
          {props.label}
        </Typography>
      )}
      {env !== 'production' && <TextField label="Haha" source="code" emptyText="No Code" />}
      {record.code && record.code !== '' ? (
        <Button
          size="small"
          variant="contained"
          sx={buttonStyles.warning}
          disabled={isLoading}
          onClick={() => setIsShowConfirm(true)}
        >
          Remove
        </Button>
      ) : null}
      <Button size="small" variant="contained" sx={buttonStyles.info} disabled={isLoading} onClick={handleNewCodeClick}>
        New Code
      </Button>
      <Confirm
        isOpen={isShowConfirm}
        loading={isLoading}
        title="Remove code"
        content="Are you sure you want to remove the code?"
        onConfirm={handleRemove}
        onClose={() => setIsShowConfirm(false)}
      />
    </Box>
  );
};

CodeView.defaultProps = { label: 'Code' };
