import ScheduleIcon from '@mui/icons-material/Schedule';
import ScheduleCreate from './ScheduleCreate';

import ScheduleEdit from './ScheduleEdit';
import ScheduleList from './ScheduleList';

export default {
  list: ScheduleList,
  icon: ScheduleIcon,
  edit: ScheduleEdit,
  create: ScheduleCreate,
  recordRepresentation: (record: any) => ` ${record.id}`,
};
