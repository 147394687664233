/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import { BooleanInput, Edit, Identifier, required, SimpleForm, TextField } from 'react-admin';

const ControlEditEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    /* Passing " " as title disables the custom title */

    <Edit title=" " id={id}>
      <SimpleForm>
        <TextField source="id" />
        <TextField source="name" />
        <BooleanInput source="is_honest_bar" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

ControlEditEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default ControlEditEmbedded;
