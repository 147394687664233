import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createBrowserHistory as createHistory } from 'history';
import buildHasuraProvider from 'ra-data-hasura';
import React from 'react';
import { Admin, DataProvider, Resource } from 'react-admin';
import { LayoutWrapper } from './components/layout-wrapper';
import { Login } from './pages';
import controls from './pages/controls';
import groups from './pages/groups';
import guests from './pages/guests';
import key_views from './pages/key_views';
import keys from './pages/keys';
import logs from './pages/logs';
import mailTemplates from './pages/mail-templates';
import mails from './pages/mails';

import orders from './pages/orders';
import products from './pages/products';
import reservations from './pages/reservations';
import rooms from './pages/rooms';
import schedules from './pages/schedules';
import settings from './pages/settings';
import syncs from './pages/syncs';
import users from './pages/users';
import { authProvider } from './providers/auth.provider';
import i18nProvider from './providers/i18n.provider';
import { lightTheme } from './services/theme';
const baseApiUrl = process.env.REACT_APP_BASE_API_URL || '';

const httpLink = createHttpLink({
  uri: baseApiUrl,
});

let headersAuth = {};
export const setAuthorizationHeader = (token: string) => {
  headersAuth = {
    authorization: `Bearer ${token}`,
  };
};

const App: React.FC = () => {
  const history = createHistory();
  const [dataProvider, setDataProvider] = React.useState<DataProvider | null>(null);
  const authLinkBuilder = () => {
    return setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...headersAuth,
        },
      };
    });
  };
  React.useEffect(() => {
    const buildDataProvider = async () => {
      let token = '';
      try {
        const auth = await authProvider.getIdentity();
        if (auth) {
          token = auth.token;
        }
      } catch (e) {
        token = '';
      }
      setAuthorizationHeader(token);
      const authLink = authLinkBuilder();
      const dataProviderHasura = await buildHasuraProvider({
        clientOptions: {
          link: authLink.concat(httpLink) as any,
        },
      });
      setDataProvider(() => dataProviderHasura);
    };
    buildDataProvider();
  }, []);

  if (!dataProvider) return <p>Loading...</p>;

  return (
    <Admin
      requireAuth={true}
      theme={lightTheme}
      layout={LayoutWrapper}
      loginPage={Login}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      history={history}
    >
      <Resource name="key_view" {...key_views} />
      <Resource name="product" {...products} />
      <Resource name="order" {...orders} />
      <Resource name="schedule" {...schedules} />
      <Resource name="reservation" {...reservations} />
      <Resource name="key" {...keys} />
      <Resource name="guest" {...guests} />
      <Resource name="room" {...rooms} />
      <Resource name="user" {...users} />
      <Resource name="group" {...groups} />
      <Resource name="object" {...controls} />
      <Resource name="sync" {...syncs} />
      <Resource name="mail" {...mails} />
      <Resource name="mail_template" {...mailTemplates} />
      <Resource name="setting" {...settings} />
      <Resource name="log" {...logs} />

      <Resource name="guest_reservation" />
      <Resource name="guest_reservations_view" />
      <Resource name="reservation_guests_view" />
      <Resource name="key_groups_view" />
      <Resource name="key_group" />
      <Resource name="product_category" />
      <Resource name="schedule_status" />

      <Resource name="item" />
    </Admin>
  );
};

export default App;
