/* eslint react/jsx-key: off */
import { ReactElement } from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
} from 'react-admin';

import { FullNameField } from '../users/FullNameField';
import OrderViewEmbedded from './OrderViewEmbedded';

// function that returns an array of filters in react admin
const getGuestFilters = (): ReactElement[] => {
  const filters = [
    <SearchInput source="confirmation_number" alwaysOn />,
    <ReferenceInput source="room_id" reference="room" alwaysOn perPage={100} sort={{ field: 'number', order: 'ASC' }}>
      <SelectInput optionText="number" />
    </ReferenceInput>,
  ];
  return filters.filter((filter) => filter !== null) as ReactElement[];
};

const OrderList = () => {
  return (
    <List
      filters={getGuestFilters()}
      sort={{
        field: 'created_at',
        order: 'DESC',
      }}
      queryOptions={{ refetchInterval: 5000 }}
    >
      <Datagrid expand={<OrderViewEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <TextField source="confirmation_number" />
        <ReferenceField source="guest_id" reference="guest">
          <FullNameField />
        </ReferenceField>

        <ReferenceField source="room_id" reference="room">
          <TextField source="number" />
        </ReferenceField>
        <NumberField source="amount" showTime locales="de-AT" />
        <DateField source="created_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default OrderList;
