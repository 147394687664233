import AddIcon from '@mui/icons-material/Add';
import PeopleIcon from '@mui/icons-material/People';
import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import {
  Button,
  ChipField,
  Datagrid,
  DateField,
  Link,
  List,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TopToolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { Role_Enum } from 'src/utils/enum';
import Aside from './Aside';
import KeyViewEmbedded from './KeyViewEmbedded';
import { LearnButtonList } from './LearnButtonList';
import { TimeSyncField } from './TimeSyncField';

export const KeyViewIcon = PeopleIcon;

const KeyActions = () => (
  <TopToolbar>
    <Button label="ADD KEY MANUALLY" startIcon={<AddIcon />} component={Link} to={{ pathname: '/key/create' }} />
  </TopToolbar>
);
const Group = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  return (
    <ReferenceArrayField label="Groups" source="group_ids" reference="group">
      <SingleFieldList linkType={false}>
        {[Role_Enum.Master, Role_Enum.Superuser].includes(permissions) ? (
          <Link to={`/key/${record.id}`}>
            <ChipField source={'name'} />
          </Link>
        ) : (
          <ChipField source={'name'} />
        )}
      </SingleFieldList>
    </ReferenceArrayField>
  );
};
const KeyViewList = () => {
  const { permissions } = usePermissions();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));

  const rowStyle = (record: any) => {
    const isCheckedIn = record.status === 'CHECKEDIN';
    const isArrivalTimeInFuture = moment(record.arrival_time).isAfter(moment());
    return {
      backgroundColor: isCheckedIn && isArrivalTimeInFuture ? '#f44336' : 'unset', // Green for completed, Red for others
    };
  };

  const filterFields = [
    <SearchInput source="full_name@_ilike,number,confirmation_number,lx_user_uuid" alwaysOn />,
    // Status dropdown
    <ReferenceInput source="status" reference="reservation_status" alwaysOn>
      <SelectInput optionText="name" source="id" />
    </ReferenceInput>,
  ];
  return (
    <List
      queryOptions={{ refetchInterval: 5000 }}
      actions={
        [Role_Enum.Master, Role_Enum.Superuser].includes(permissions) ? <KeyActions /> : <TopToolbar></TopToolbar>
      }
      // filter={{ 'departure_date@_gte': moment().format('YYYY-MM-DD') }}
      sort={{ field: 'arrival_time', order: 'DESC' }}
      filterDefaultValues={{ 'departure_time@_gte': moment().format('YYYY-MM-DD') }}
      aside={<Aside />}
      filters={isMobile ? filterFields : []}
      exporter={false}
    >
      <Datagrid
        bulkActionButtons={false}
        expand={[Role_Enum.Master, Role_Enum.Superuser].includes(permissions) ? <KeyViewEmbedded /> : <> </>}
        rowClick="expand"
        optimized
        rowStyle={rowStyle}
      >
        {permissions === Role_Enum.Master && <TextField source="id" />}
        <ReferenceField label="Confirmation" source="reservation_id" reference="reservation">
          <TextField source="confirmation_number" />
        </ReferenceField>
        <TextField source="full_name" />
        <TextField source="status" />
        {/* <BooleanField source="is_sent_mail" /> */}
        <LearnButtonList />
        <TextField source="number" />
        <TimeSyncField />
        <Group />
        <DateField source="arrival_time" showTime locales="de-AT" />
        <DateField label="Valid Until" source="departure_time" showTime locales="de-AT" />
        {permissions === Role_Enum.Master && <DateField source="updated_at" showTime locales="de-AT" />}
      </Datagrid>
    </List>
  );
};

export default KeyViewList;
